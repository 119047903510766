import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import React, { useState } from "react";
import { useAuth } from "reactfire";
import useUser from "../../../../providers/user_provider/user_provider";
import { useFeedback } from "../../../../providers/feedback/feeedback";

function ChangePassword(props: any, ref: any) {
  const auth = useAuth();
  const { email } = useUser();
  const { showSnackbar } = useFeedback();
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setErrorMsg] = useState({
    password: "",
  });

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  const handleReauthenticate = async () => {
    if (newPassword.length < 6) {
      setErrorMsg({
        ...error,
        password: "Password should be at least 6 characters",
      });
    } else if (newPassword !== confirmPassword) {
      setErrorMsg({
        ...error,
        password: "Password don't match",
      });
    } else {
      const credentials = EmailAuthProvider.credential(email!, oldPassword);
      await reauthenticateWithCredential(auth?.currentUser!, credentials)
        .then((userCredentials) => {
          console.log("User re-authenticated successfully");
          handleChangePassword();
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode, errorMessage);
          if (errorCode === "auth/invalid-credential") {
            showSnackbar("Please enter valid old password");
          }
        });
    }
  };

  const handleChangePassword = async () => {
    try {
      await updatePassword(auth?.currentUser!, newPassword);
      showSnackbar("Password updated successfully");
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      showSnackbar("Something went wrong!!! Please try again");
    }
  };

  return (
    <Grid item lg={6} md={6} sm={12} mb={3} mt={10} width="100%">
      <Box ref={ref}>
        <Typography variant="h5">Change password</Typography>
      </Box>
      <Box mt={3}>
        <Typography mt={2}>Old password</Typography>
        <TextField
          placeholder="Enter old password"
          fullWidth
          value={oldPassword}
          sx={{ mt: 1 }}
          onChange={(e) => {
            setOldPassword(e.target.value);
          }}
          //   helperText={error.password}
          FormHelperTextProps={{ sx: { color: "red" } }}
          type={showOldPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowOldPassword(!showOldPassword)}
                >
                  {showOldPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Typography mt={2}>New password</Typography>
        <TextField
          placeholder="Enter new password"
          fullWidth
          value={newPassword}
          sx={{ mt: 1 }}
          onChange={(e) => {
            setNewPassword(e.target.value);
            setErrorMsg({ ...error, password: "" });
          }}
          helperText={error.password}
          FormHelperTextProps={{ sx: { color: "red" } }}
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Typography mt={2}>Confirm password</Typography>
        <TextField
          placeholder="Enter confirm password"
          fullWidth
          value={confirmPassword}
          sx={{ mt: 1 }}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
          }}
          FormHelperTextProps={{ sx: { color: "red" } }}
          type={showConfirmPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        display="flex"
        mt={3}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Button
          variant="contained"
          sx={{ minWidth: 150 }}
          onClick={handleReauthenticate}
          disabled={oldPassword && newPassword ? false : true}
        >
          Save changes
        </Button>
      </Box>
    </Grid>
  );
}

export default React.forwardRef(ChangePassword);
