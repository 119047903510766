import { Box, Button, Grid, Hidden, Typography, useTheme } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

export default function LoginLayout({ children }: PropsWithChildren) {
  const theme = useTheme();
  let images = [
    {
      id: 1,
      img: "images/icons/dyslexia_1.png",
    },
    {
      id: 2,
      img: "images/icons/adhd_1.png",
    },
  ];
  return (
    <Box display="flex" flexDirection="row" width={"100%"} height="100vh">
      <Grid
        container
        display="flex"
        flexDirection="row"
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item lg={6} md={6} sm={12} xs={12} mb={1} width="100%">
          <Box display="flex" flexDirection={"column"} mx={2}>
            <Box
              display="flex"
              mt={2}
              sx={{ justifyContent: "center", alignItems: "center" }}
            >
              <img
                src="images/icons/readbest-cropped.png"
                alt=""
                height={100}
                width={200}
              />
            </Box>
            <Box
              display="flex"
              mt={2}
              sx={{ justifyContent: "center", alignItems: "center" }}
            >
              <a
                href="https://readbest.ca/"
                color="#637381"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <Typography variant="caption" color="#637381">
                  READ HELP TECHNOLOGIES INC.
                </Typography>
              </a>
            </Box>

            {children}
          </Box>
        </Grid>
        <Hidden mdDown>
          <Grid item lg={6} md={6}>
            <Carousel
              autoPlay={true}
              dynamicHeight={false}
              showStatus={false}
              showThumbs={false}
              infiniteLoop={true}
              showArrows={false}
              interval={2000}
              renderItem={(children) => {
                return <Box mb={6}>{children}</Box>;
              }}
              renderIndicator={(onClick, isSelected) => {
                return (
                  <Box
                    display="flex"
                    mx={0.2}
                    mt={10}
                    sx={{
                      borderRadius: 8,
                      width: isSelected ? 24 : 8,
                      transition: "all 1s",
                      height: 8,
                      backgroundColor: isSelected
                        ? theme.palette.primary.main
                        : "white",
                      border: `1px solid ${theme.palette.primary.main}`,
                    }}
                  ></Box>
                );
              }}
            >
              {images.map((image) => {
                return (
                  <Box
                    sx={{
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        "& ::before": {
                          content: "''",
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: 50,
                          height: 50,
                          borderTopRightRadius: 50,
                          boxShadow: "-15px -15px 0 15px #fff",
                        },
                      }}
                    >
                      <img
                        style={{
                          height: "80vh",
                          width: "80vh",
                          borderRadius: 20,
                          objectFit: "cover",
                        }}
                        src={image.img}
                        alt=""
                      />
                    </Box>
                  </Box>
                );
              })}
            </Carousel>
          </Grid>
        </Hidden>
      </Grid>
    </Box>
  );
}
