import {
  ArticleOutlined,
  BookmarkAddOutlined,
  LinkOutlined,
  PictureAsPdfOutlined,
  PrintOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Skeleton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { Timestamp, addDoc, collection } from "firebase/firestore";
import { useState } from "react";
import { useFirestore } from "reactfire";
import URL, { InputType } from "../../../../components/url/url";
import { httpClient } from "../../../../config";
import useUser from "../../../../providers/user_provider/user_provider";

export default function Summarizer({
  selectBookmarksTab,
}: {
  selectBookmarksTab: () => void;
}) {
  const [input, setInput] = useState("");
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showArticle, setShowArticle] = useState(false);
  const [inputType, setInputType] = useState<InputType>(InputType.TEXT);
  const [isAddToBookmarkIsOpen, setAddToBookmarkIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const { uid, accessToken } = useUser();

  const firestore = useFirestore();
  const summarizersCollection = collection(
    firestore,
    "users",
    uid!,
    "summarizers"
  );

  function openAddToBookmark() {
    setAddToBookmarkIsOpen(true);
  }

  function closeAddToBookmark() {
    setAddToBookmarkIsOpen(false);
    setTitle("");
  }

  function createBookmark() {
    addDoc(summarizersCollection, {
      title: title,
      input: input,
      summary: data,
      type: getInputType(),
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
    }).then(() => {
      selectBookmarksTab();
    });
    setTitle("");
    setAddToBookmarkIsOpen(false);
  }

  function getInputType(): string {
    switch (inputType) {
      case InputType.TEXT:
        return "TEXT";
      case InputType.LINK:
        return "LINK";
      case InputType.FILE:
        return "FILE";
    }
  }
  // const { data } = useQuery({
  //   queryKey: ["todos", input],
  //   queryFn: async () => {
  //     let token = await user.data?.getIdToken();
  //     let { data } = await httpClient.post(
  //       "/summary",
  //       {
  //         type: "url",
  //         value: input,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     return data?.data;
  //   },
  //   enabled: input.length > 0,
  //   staleTime: Infinity,
  // });

  async function submit() {
    setIsLoading(true);
    setShowArticle(true);
    setData(null);
    let inputType = getInputType();
    let token = accessToken;
    let { data } = await httpClient.post(
      "/summary",
      {
        type: inputType.toLowerCase(),
        value: input,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data.data) {
      setIsLoading(false);
      setData(data.data);
    } else {
      setIsLoading(false);
      setShowArticle(false);
      setData(null);
    }
  }

  function download() {
    try {
      (window as any)
        .html2pdf()
        .set({ filename: "Summary - ReadBest.pdf" })
        .from(document.getElementById("article"))
        .save();
    } catch (e) {}
  }

  function print() {
    try {
      let article = document.getElementById("article");
      let newWindow = window.open("", "PRINT", "height=400,width=600");
      var styleTags = document.head.querySelectorAll("style");

      // Iterate through each style tag and copy its content to the print window

      styleTags.forEach(function (styleTag) {
        if (newWindow && styleTag) {
          var newStyleTag = newWindow.document.createElement("style");
          newStyleTag.innerHTML = styleTag.innerHTML;
          newWindow.document.head.appendChild(newStyleTag);
        }
      });

      var linkTags = document.head.querySelectorAll('link[rel="stylesheet"]');
      linkTags.forEach(function (linkTag) {
        var newLinkTag = newWindow?.document.createElement("link");
        if (newWindow && newLinkTag) {
          newLinkTag.rel = "stylesheet";
          newLinkTag.href = (linkTag as any).href!;
          newWindow.document.head.appendChild(newLinkTag);
        }
      });

      if (newWindow) {
        newWindow.document.head.innerHTML += `<style>* { font-family: 'Public Sans', sans-serif !important; }</style>`;
        newWindow.document.body.innerHTML += (article as any)?.innerHTML;
        newWindow?.document.close();
        newWindow?.focus();
        newWindow?.print();
      }
      // newWindow?.close();
    } catch (e) {}
  }

  return (
    <Box>
      <Box display="flex" flexDirection="column" alignItems="center" m={2}>
        <Box
          // mr={1}
          p={2}
          // pr={1}
          display="flex"
          justifyContent="center"
          alignItems="start"
          flexDirection="column"
        >
          <ToggleButtonGroup
            value={inputType}
            exclusive
            onChange={(e, value) => setInputType(value)}
          >
            <ToggleButton value={InputType.TEXT} aria-label="module">
              <ArticleOutlined />
            </ToggleButton>
            <ToggleButton value={InputType.LINK} aria-label="list">
              <LinkOutlined />
            </ToggleButton>
            {/* <ToggleButton value={InputType.FILE} aria-label="module">
              <FileUploadOutlined />
            </ToggleButton> */}
          </ToggleButtonGroup>
        </Box>
        <URL
          value={input}
          onChange={(value) => {
            setInput(value);
          }}
          inputType={inputType}
        />
      </Box>
      <Box display="flex" justifyContent="center">
        <Button variant="contained" size="large" onClick={submit}>
          Submit
        </Button>
      </Box>

      {showArticle ? isLoading ? (<Box display={'flex'} p={6} justifyContent={'center'} alignItems={'center'}><CircularProgress /></Box>) :(
        <Box mt={1} p={2} mr={4}>
          <Box
            bgcolor={blue[50]}
            border={`2px dashed ${blue[400]}`}
            borderRadius={1}
            p={4}
            pt={3}
            width={"100%"}
          >
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h6">Article Summary</Typography>
            </Box>
            <Typography variant="body1" pt={2}>
              {data}
            </Typography>
          </Box>

          <Box display="flex" mt={1}>
            <Box mx={1}>
              <Button
                startIcon={<BookmarkAddOutlined />}
                onClick={openAddToBookmark}
              >
                Add to bookmark
              </Button>
            </Box>
            <Box mx={1}>
              <Button onClick={print} startIcon={<PrintOutlined />}>
                Print
              </Button>
            </Box>
            <Box mx={1}>
              <Button onClick={download} startIcon={<PictureAsPdfOutlined />}>
                Export
              </Button>
            </Box>
          </Box>
        </Box>
      ):null}

      {isAddToBookmarkIsOpen && (
        <Dialog
          fullWidth
          open={isAddToBookmarkIsOpen}
          onClose={closeAddToBookmark}
        >
          <DialogTitle>Add to bookmark</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              id="name"
              label="Title"
              type="text"
              fullWidth
              variant="standard"
              value={title}
              onChange={({ target: { value } }) => setTitle(value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAddToBookmark}>Cancel</Button>
            <Button onClick={createBookmark}>Add</Button>
          </DialogActions>
        </Dialog>
      )}

      {showArticle && (
        <Box sx={{ display: "none" }}>
          <Box id="article" m={4}>
            <Typography variant="h5" pb={4}>
              Article Summary
            </Typography>
            <Typography variant="body1">{data || ""}</Typography>
            <Box my={4}>
              <Divider />
            </Box>
            <Typography variant="body1" pb={2}>
              Original Article
            </Typography>
            <Typography variant="body2">{input || ""}</Typography>

            <Box mt={4} mb={2}>
              <Divider />
            </Box>
            <Box>
              <Typography variant="caption">
                Generated using <b>ReadBest Summarizer</b>.
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
