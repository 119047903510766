import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import LoginLayout from "../layouts/login/login_layout";
import {
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import useUser from "../providers/user_provider/user_provider";
import { useFeedback } from "../providers/feedback/feeedback";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAnalytics, useAuth } from "reactfire";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { logEvent } from "firebase/analytics";
// import googleIcon from "icons";

const TextField = styled(MuiTextField)(({ theme }) => {
  return {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "transparent", // Border color when focused
      },
      "&:hover fieldset": {
        borderColor: "transparent", // Border color on hover
      },
      "& fieldset": {
        borderColor: "transparent", // Default border color
      },
      backgroundColor: "#919eab33",
    },
  };
});

export default function Login() {
  const [userEmail, setUserEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setErrorMsg] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { setFullName, setEmail, setProfilePic, setAccessToken } = useUser();
  const { showSnackbar } = useFeedback();
  const auth = useAuth();
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const [searchParams, setUrlParam] = useSearchParams();

  const handleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then((user) => {
          setFullName(user?.user?.displayName || "");
          setEmail(user?.user?.email || "");
          setProfilePic(user?.user?.photoURL || "");
          user?.user?.getIdToken().then((userToken) => {
            setAccessToken(userToken || "");
          });
          logEvent(analytics, "sso", { uid: user?.user.uid });
          let redirectUrl = searchParams?.get("redirect");
          if (redirectUrl) {
            navigate(redirectUrl);
          } else {
            navigate("/home");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      console.error("Error signing in:");
    }
  };

  function _signInWithUSernameAndPassword() {
    const regEx =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!regEx.test(userEmail)) {
      setErrorMsg({ ...error, email: "Please enter valid email" });
    }
    // else if (password.length < 6) {
    //   setErrorMsg({
    //     ...error,
    //     password: "Password should be at least 6 characters",
    //   });
    // }
    else {
      setIsLoading(true);
      signInWithEmailAndPassword(auth, userEmail, password)
        .then((userCredential) => {
          setIsLoading(false);
          logEvent(analytics, "signin", { uid: userCredential?.user.uid });
          let redirectUrl = searchParams?.get("redirect");
          if (redirectUrl) {
            navigate(redirectUrl);
          } else {
            navigate("/home");
          }
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode, errorMessage);
          if (errorCode === "auth/invalid-credential") {
            // showSnackbar("You are not registered with us. Please sign up.");
            showSnackbar("Please enter valid credentials");
          }
          if (errorCode === "auth/weak-password") {
            setErrorMsg({
              ...error,
              password: "Password should be at least 6 characters",
            });
          }
          setIsLoading(false);
        });
    }
  }
  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  useEffect(() => {
    if (auth?.currentUser) {
      navigate("/home");
    }
  }, [auth]);

  return (
    <LoginLayout>
      <Grid container justifyContent={"center"}>
        <Grid item lg={8} md={10} sm={12} mb={3} width="100%">
          <Box mt={8}>
            <Typography variant="h4" textAlign={"center"}>
              Login
            </Typography>
          </Box>
          <Box
            display="flex"
            mt={4}
            width="100%"
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="outlined"
              fullWidth
              startIcon={
                <img
                  src="images/icons/google-icon.png"
                  alt=""
                  height={20}
                  width={20}
                />
              }
              sx={{
                minWidth: 100,
                height: 50,
                justifyContent: "center",
                alignItems: "center",
                ml: 1,
              }}
              onClick={handleSignIn}
            >
              Login with Google
            </Button>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            width="100%"
            mt={3}
          >
            <Divider flexItem={true} />
            <Typography variant="body1" color="textSecondary" sx={{ mx: 2 }}>
              OR
            </Typography>
            <Divider flexItem={true} />
          </Box>
          <Box mt={2}>
            <Typography mt={2}>Email address</Typography>
            <TextField
              placeholder="Enter your email address"
              fullWidth
              value={userEmail}
              sx={{ mt: 1 }}
              onChange={(e) => {
                setUserEmail(e.target.value);
                setErrorMsg({ ...error, email: "" });
              }}
              helperText={error.email}
              FormHelperTextProps={{ sx: { color: "red" } }}
            />
            <Typography mt={2}>Password</Typography>
            <TextField
              placeholder="Enter  password"
              fullWidth
              value={password}
              sx={{ mt: 1 }}
              onChange={(e) => {
                setPassword(e.target.value);
                setErrorMsg({ ...error, password: "" });
              }}
              type={showPassword ? "text" : "password"}
              helperText={error.password}
              FormHelperTextProps={{ sx: { color: "red" } }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box mt={3}>
            {isLoading ? (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent={"center"}
                alignItems="center"
              >
                <CircularProgress />
              </Box>
            ) : (
              <Button
                disabled={userEmail && password ? false : true}
                variant="contained"
                fullWidth
                size="large"
                onClick={_signInWithUSernameAndPassword}
              >
                Login
              </Button>
            )}
          </Box>
          <Grid item lg={12} md={12} sm={12}>
            <Grid container mt={1}>
              <Grid
                item
                display="flex"
                flexDirection="row"
                lg={6}
                md={6}
                sm={12}
              >
                <Typography variant="button">Don't have an account?</Typography>
                <Button
                  variant="text"
                  sx={{ m: 0, p: 0 }}
                  onClick={() => navigate("/signup")}
                >
                  Register
                </Button>
              </Grid>
              <Grid item lg={6} md={6} sm={12} textAlign={"end"}>
                <Button
                  variant="text"
                  sx={{ m: 0, p: 0 }}
                  onClick={() => navigate("/forgotPassword")}
                >
                  Forgetten Password?
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </LoginLayout>
  );
}
