"use client";
import {
  InputAdornment,
  TextField as MuiTextField,
  styled,
} from "@mui/material";
import React from "react";
import {
  NotificationsOutlined,
  Search as SearchIcon,
} from "@mui/icons-material";
import { grey } from "@mui/material/colors";

const TextField = styled(MuiTextField)(({ theme }) => {
  return {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "transparent", // Border color when focused
      },
      "&:hover fieldset": {
        borderColor: "transparent", // Border color on hover
      },
      "& fieldset": {
        borderColor: "transparent", // Default border color
      },
    },
  };
});

export default function Search({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: string) => void;
}) {
  return (
    <TextField
      // value={searchValue}
      // onChange={(e) => setSearchValue(e.target.value)}
      size="small"
      fullWidth
      value={value}
      onChange={({target: {value}}) => onChange(value)}
      sx={{
        bgcolor: grey[100],
        borderRadius: 3,
        borderColor: "transparent",
      }}
      placeholder="Search"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon sx={{ color: "text.primary" }} />
          </InputAdornment>
        ),
      }}
    />
  );
}
