import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../layouts/main/main_layout";
import {
  DocumentData,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
} from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import NoDataFound from "../no_data_found/no_data_found";

interface GradesInterface {
  id: number;
  name: string;
  order: number;
  icon: string;
}

export default function Grades({ concept }: { concept: string }) {
  const [subjectCollection, setSubjectCollection] = useState<DocumentData[]>(
    []
  );
  const [selectedGrade, setSelectedGrade] = useState<GradesInterface | null>(
    null
  );
  const navigate = useNavigate();
  const firestore = useFirestore();
  const [isCreateReadDialogIsOpen, setIsCreateReadDialogIsOpen] =
    useState<boolean>(false);

  const collectionData = collection(firestore, "learnings");
  const chapterCollectionQuery = query(
    collectionData
    // orderBy("order", "asc")
  );

  const {
    status,
    data: grades,
    hasEmitted,
  } = useFirestoreCollectionData(chapterCollectionQuery, {
    idField: "id",
  });

  async function getSubjects(selectedGrade: string) {
    const outerCollectionPath = concept;
    const nestedCollectionPath = "subjects";

    // Step 1: Get the document from the outer collection
    const outerDocRef = doc(firestore, outerCollectionPath, selectedGrade);
    const outerDocSnapshot = await getDoc(outerDocRef);

    if (outerDocSnapshot?.exists()) {
      const nestedCollectionRef = collection(outerDocRef, nestedCollectionPath);
      const nestedCollectionQuery = query(nestedCollectionRef);
      const nestedCollectionSnapshot = await getDocs(nestedCollectionQuery);

      let chaptersBasedOnGrade: DocumentData[] = [];
      nestedCollectionSnapshot.forEach((nestedDoc) => {
        const nestedData = nestedDoc.data();
        chaptersBasedOnGrade.push({ ...nestedData, id: nestedDoc.id });
      });
      setSubjectCollection(chaptersBasedOnGrade);
    }
    setIsCreateReadDialogIsOpen(true);
  }

  function dyslexia() {
    return (
      <Grid container spacing={3} mt={3}>
        {grades?.length !== 0 ? (
          grades?.map((item) => {
            return (
              <Grid item md={3} lg={3} sm={12} xs={12}>
                <Button
                  onClick={() => {
                    getSubjects(item?.id);
                    setSelectedGrade({
                      icon: item?.icon,
                      id: item?.id,
                      name: item.name,
                      order: item?.order,
                    });
                    console.log(item?.id);
                  }}
                  fullWidth
                >
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      minHeight: 300,
                      border: "2px dotted #CCCCCC",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1" mt={2} fontWeight={"600"}>
                      {item?.name}
                    </Typography>
                    <Box
                      display={"flex"}
                      height={"fit-content"}
                      width={"100%"}
                      justifyContent={"center"}
                      mt={5}
                      dangerouslySetInnerHTML={{ __html: item?.icon }}
                    />
                  </Card>
                </Button>
              </Grid>
            );
          })
        ) : (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
            my={2}
          >
            <NoDataFound />
          </Box>
        )}
      </Grid>
    );
  }

  return (
    <MainLayout>
      <Box mt={6}>
        <Box>
          <Typography
            variant="subtitle2"
            color="#637381"
            sx={{ textTransform: "capitalize" }}
          >
            GRADES
          </Typography>
          <Typography variant="h3" fontWeight={"600"} mt={3}>
            Explore Grade
          </Typography>
          <Typography variant="h3" fontWeight={"600"}>
            For Your Subjects
          </Typography>
        </Box>
        <Box
          mt={3}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {hasEmitted ? (
            dyslexia()
          ) : (
            <Box display={"flex"} minHeight={200} alignItems={"center"}>
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Box>
      <Dialog fullWidth open={isCreateReadDialogIsOpen}>
        <DialogTitle textAlign={"center"}>Select Subject</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item lg={12} md={12} sm={12}>
              <Grid container spacing={3}>
                {subjectCollection?.map((res: DocumentData) => {
                  return (
                    <Grid item lg={4} md={4} sm={6}>
                      <Button
                        fullWidth
                        sx={{
                          border: "2px dotted #CCCCCC",
                          borderRadius: 10,
                          color: "#1B001B",
                        }}
                        onClick={() => {
                          navigate(
                            `/learnings/grade/${selectedGrade?.id}/${res?.id}`
                          );
                        }}
                      >
                        <Typography variant="button" fontWeight={"600"} p={0}>
                          {res.name}
                        </Typography>
                      </Button>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container m={2}>
            <Grid item md={6} lg={6} sm={6}></Grid>
            <Grid item md={6} lg={6} sm={6}>
              <Button
                fullWidth
                variant="outlined"
                onClick={() => setIsCreateReadDialogIsOpen(false)}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </MainLayout>
  );
}
