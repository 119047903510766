import { createBrowserRouter } from "react-router-dom";
import Home from "./views/home/home";
import Dyslexia from "./views/dyslexia/dyslexia";
import ADHD from "./views/adhd/adhd";
import Login from "./login/login";
import Signup from "./signup/signup";
import AccessDenied from "./views/access_denied/access_denied";
import useUser from "./providers/user_provider/user_provider";
import ForgotPassword from "./login/forgot_password/forgot_password";
import Profile from "./views/profile/profile";
import Grades from "./views/grades/grades";
import Chapter from "./views/chapter/chapter";

interface PrivateRouteProps {
  element: React.ReactNode;
  // You can include other props as needed
}
const PrivateRoute: any = ({ element }: PrivateRouteProps) => {
  const { isActiveUser } = useUser();
  return isActiveUser ? element : <AccessDenied />;
};

export const routes = [
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/dyslexia/reads",
    element: <PrivateRoute element={<Dyslexia />} />,
  },
  {
    path: "/adhd/summarizer",
    element: <PrivateRoute element={<ADHD />} />,
  },
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/forgotPassword",
    element: <ForgotPassword />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/profile",
    element: <PrivateRoute element={<Profile />} />,
  },
  // {
  //   path: "/dyslexia/grade/:gradesId/:chapterId",
  //   element: <PrivateRoute element={<Chapter concept={"dyslexia"} />} />,
  // },
  // {
  //   path: "/adhd/grade/:gradesId/:chapterId",
  //   element: <PrivateRoute element={<Chapter concept={"adhd"} />} />,
  // },
  // {
  //   path: "dyslexia/grades",
  //   element: <PrivateRoute element={<Grades concept={"dyslexia"} />} />,
  // },
  // {
  //   path: "adhd/grades",
  //   element: <PrivateRoute element={<Grades concept={"adhd"} />} />,
  // },
  {
    path: "/learnings/grade/:gradesId/:chapterId",
    element: <PrivateRoute element={<Chapter concept={"learnings"} />} />,
  },
  {
    path: "/learning",
    element: <PrivateRoute element={<Grades concept={"learnings"} />} />,
  },
];

export default createBrowserRouter(routes);
