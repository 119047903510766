export function readText(
  textToRead: string,
  { onStart, onEnd }: { onStart: () => void; onEnd: () => void }
) {
  try {
    if ("speechSynthesis" in window) {
      const synthesis = window.speechSynthesis;

      // Cancel any previous speech
      synthesis.cancel();

      const utterance = new SpeechSynthesisUtterance(textToRead);

      utterance.onstart = function () {
        onStart();
      };

      utterance.onend = function () {
        onEnd();
      };

      utterance.onerror = function (event) {
        onEnd();
      };

      // Set the language and voice characteristics
      utterance.lang = "en-CA"; // Canadian English
      utterance.voice = getCanadianFemaleVoice();

      // Speak the text
      synthesis.speak(utterance);
    } else {
      console.error("Speech synthesis not supported");
    }
  } catch (e) {}
}

export function stopTalking() {
  try {
    if ("speechSynthesis" in window) {
      const synthesis = window.speechSynthesis;

      // Cancel any previous speech
      synthesis.cancel();
    }
  } catch (e) {}
}

function getCanadianFemaleVoice() {
  const voices = window.speechSynthesis.getVoices();
  // Find a Canadian female voice by name (adjust the criteria as needed)
  const canadianFemaleVoice = voices.find(
    (voice) =>
      voice.name.toLowerCase().includes("female") && voice.lang === "en-CA"
  );

  return canadianFemaleVoice || voices[0];
}
