import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "@fontsource-variable/plus-jakarta-sans";
import { FirebaseAppProvider } from "reactfire";
import { QueryClient, QueryClientProvider } from "react-query";

const firebaseConfig = {
  apiKey: "AIzaSyDM1zJNbHKvp3JFkzCvkUMm3ulVkya940M",
  authDomain: "readbest-prod.firebaseapp.com",
  projectId: "readbest-prod",
  storageBucket: "readbest-prod.appspot.com",
  messagingSenderId: "703265848447",
  appId: "1:703265848447:web:ec9f93c4e9f53072d7934a",
  measurementId: "G-8XXBG0QZ1D"
};

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <App />
      </FirebaseAppProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
