"use client";

import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import LoginLayout from "../layouts/login/login_layout";
import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
} from "firebase/auth";
import { useAnalytics, useAuth, useFirestore } from "reactfire";
import useUser from "../providers/user_provider/user_provider";
import { useNavigate } from "react-router-dom";
import { useFeedback } from "../providers/feedback/feeedback";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { logEvent } from "firebase/analytics";
import MuiPhoneNumber from "mui-phone-number";
import { Timestamp, addDoc, collection } from "firebase/firestore";

export default function Signup() {
  const [userFullName, setUserFullName] = useState<string | null>("");
  const [userEmail, setUserEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [error, setErrorMsg] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userMobileNumber, setUserMobileNumber] = useState<string | null>("");

  const { showSnackbar } = useFeedback();
  const auth = useAuth();
  const navigate = useNavigate();
  const { setAccessToken, setFullName } = useUser();
  const analytics = useAnalytics();
  const firestore = useFirestore();

  const handleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then((userCredentials) => {
          userCredentials?.user?.getIdToken().then((userToken) => {
            setAccessToken(userToken || "");
          });
          logEvent(analytics, "sso", { uid: userCredentials?.user.uid });
          navigate("/home");
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      console.error("Error signing in:");
    }
  };

  async function _signUpWithUSernameAndPassword() {
    const regEx =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!regEx.test(userEmail)) {
      setErrorMsg({ ...error, email: "Please enter valid email" });
    } else if (password.length < 6) {
      setErrorMsg({
        ...error,
        password: "Password should be at least 6 characters",
      });
    } else if (password !== confirmPassword) {
      setErrorMsg({
        ...error,
        password: "Password don't match",
      });
    } else {
      setIsLoading(true);
      createUserWithEmailAndPassword(auth, userEmail, password)
        .then((userCredential) => {
          const user = userCredential.user;
          updateProfile(user, {
            displayName: userFullName,
          }).then((res) => {
            setFullName(userFullName!);
          });
          logEvent(analytics, "signup", { uid: user.uid });
          setIsLoading(false);
          addPersonalDetails(userCredential.user.uid);
          navigate("/home");
        })
        .catch((error) => {
          const errorCode = error.code;
          if (errorCode === "auth/email-already-in-use") {
            showSnackbar("Email already exist");
          }
          if (errorCode === "auth/weak-password") {
            setErrorMsg({
              ...error,
              password: "Password should be at least 6 characters",
            });
          }
          setIsLoading(false);
        });
    }
  }

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  function addPersonalDetails(uid: string) {
    const usersCollection = collection(
      firestore,
      "users",
      uid!,
      "personalDetails"
    );
    addDoc(usersCollection, {
      mobileNumber: userMobileNumber,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
    }).then(() => {});
  }

  return (
    <LoginLayout>
      <Grid container justifyContent={"center"}>
        <Grid item lg={8} md={8} sm={12} mb={3} width="100%">
          <Box mt={8}>
            <Typography variant="h4" textAlign={"center"}>
              Create your account
            </Typography>
          </Box>
          <Box
            display="flex"
            mt={4}
            width="100%"
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="outlined"
              fullWidth
              startIcon={
                <img
                  src="images/icons/google-icon.png"
                  alt=""
                  height={20}
                  width={20}
                />
              }
              sx={{
                minWidth: 100,
                height: 50,
                justifyContent: "center",
                alignItems: "center",
                ml: 1,
              }}
              onClick={handleSignIn}
            >
              Login with Google
            </Button>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            width="100%"
            mt={3}
          >
            <Divider flexItem={true} />
            <Typography variant="body1" color="textSecondary" sx={{ mx: 2 }}>
              OR
            </Typography>
            <Divider flexItem={true} />
          </Box>
          <Box mt={2}>
            <Typography my={1}>Name</Typography>
            <TextField
              variant="outlined"
              placeholder="Enter your full name"
              fullWidth
              value={userFullName}
              onChange={(e) => {
                setUserFullName(e.target.value);
              }}
            />
            <Typography mt={2}>Email address</Typography>
            <TextField
              placeholder="Enter your email address"
              fullWidth
              value={userEmail}
              sx={{ mt: 1 }}
              onChange={(e) => {
                setUserEmail(e.target.value);
                setErrorMsg({ ...error, email: "" });
              }}
              helperText={error.email}
              FormHelperTextProps={{ sx: { color: "red" } }}
            />
            <Typography mt={2}>Mobile number</Typography>
            <MuiPhoneNumber
              variant="outlined"
              defaultCountry={"ca"}
              fullWidth
              onChange={(e) => {
                console.log(e, "e");

                setUserMobileNumber(e.toString().trim());
              }}
              sx={{ mt: 1 }}
              onlyCountries={["ca"]}
              placeholder="Enter your mobile number"
            />
            <Typography mt={2}>Password</Typography>
            <TextField
              placeholder="Enter  password"
              fullWidth
              value={password}
              sx={{ mt: 1 }}
              onChange={(e) => {
                setPassword(e.target.value);
                setErrorMsg({ ...error, password: "" });
              }}
              helperText={error.password}
              FormHelperTextProps={{ sx: { color: "red" } }}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Typography mt={2}>Confirm Password</Typography>
            <TextField
              placeholder="Enter confirm password"
              value={confirmPassword}
              fullWidth
              sx={{ mt: 1 }}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              type={showPassword ? "text" : "password"}
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box mt={3}>
            {isLoading ? (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent={"center"}
                alignItems="center"
              >
                <CircularProgress />
              </Box>
            ) : (
              <Button
                disabled={
                  userFullName && userEmail && password && confirmPassword
                    ? false
                    : true
                }
                variant="contained"
                fullWidth
                size="large"
                onClick={_signUpWithUSernameAndPassword}
              >
                Register
              </Button>
            )}
          </Box>

          <Box mt={1} display="flex" flexDirection="row">
            <Typography>Already have an account?</Typography>

            <Button
              variant="text"
              sx={{ m: 0, p: 0 }}
              onClick={() => navigate("/")}
            >
              Login
            </Button>
          </Box>
        </Grid>
      </Grid>
    </LoginLayout>
  );
}
