import ExampleTheme from "../themes/ExampleTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import TreeViewPlugin from "./plugins/TreeViewPlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";

import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import { useEffect, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
// import { OnChangeDebounce } from "./plugins/onchangedebounce";

function Placeholder() {
  return <div className="editor-placeholder">Write Something...</div>;
}

function OnChangePlugin({ onChange }) {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      onChange(editorState);
    });
  }, [editor, onChange]);
}


function UpdateValuePlugin({ editorState }) {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    const state = editor.parseEditorState(editorState);
    if(editorState){
      editor.setEditorState(state);

    }
  }, []);
}


const handleFontChange = (selectedFont) => {
  // Implement logic to apply the selected font to the editor content
  // You might use RichUtils or other editor-specific APIs
};


const editorConfig = {

  // The editor theme
  theme: ExampleTheme,
  // Handling of errors during update
  onError(error) {
    // throw error;
  },
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode
  ],

};




export default function Editor({ editorState, onChange }) {
  // const [editorState, setEditorState] = useState();
  // function onChange(editorState) {
  //   // Call toJSON on the EditorState object, which produces a serialization safe string
  //   const editorStateJSON = editorState.toJSON();
  //   // However, we still have a JavaScript object, so we need to convert it to an actual string with JSON.stringify
  //   // setEditorState(JSON.stringify(editorStateJSON));

  //   console.log(editorState);

  //   onChange()
  // }



  return (
    <LexicalComposer initialConfig={{ ...editorConfig }}>
      <div className="editor-container">
        <ToolbarPlugin />
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={<Placeholder />}
          // ErrorBoundary={LexicalErrorBoundary}
          />
          {/* <DragDropPaste /> */}
          <HistoryPlugin />
          {/* <TreeViewPlugin /> */}
          <AutoFocusPlugin />
          <CodeHighlightPlugin />
          <ListPlugin />
          <LinkPlugin />
          <AutoLinkPlugin />
          <ListMaxIndentLevelPlugin maxDepth={7} />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />

          <OnChangePlugin onChange={onChange} />
<UpdateValuePlugin editorState={editorState} />

        </div>
      </div>
    </LexicalComposer>
  );
}
