import {
  DeleteForeverOutlined,
  PictureAsPdfOutlined,
  PrintOutlined,
  RecordVoiceOverOutlined,
  SaveOutlined,
  StopCircleOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField as MuiTextField,
  Typography,
  styled,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import CreateARead from "../../components/create_a_read/create_a_read";
import Editor from "../../components/editor/editor";
import Search from "../../components/search/search";
import MainLayout from "../../layouts/main/main_layout";
import useUser from "../../providers/user_provider/user_provider";
import { readText, stopTalking } from "../../utils/speach_to_text";

dayjs.extend(relativeTime);

const TextField = styled(MuiTextField)(({ theme }) => {
  return {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "transparent", // Border color when focused
      },
      "&:hover fieldset": {
        borderColor: "transparent", // Border color on hover
      },
      "& fieldset": {
        borderColor: "transparent", // Default border color
      },
      "& :hover": {
        backgroundColor: grey[100],
        borderRadius: 24,
        padding: theme.spacing(1),
      },
      "& :focus": {
        backgroundColor: grey[100],
        borderRadius: 24,
        padding: theme.spacing(1),
      },
      "& input": {
        padding: 0,
        ...theme.typography.h6,
      },
    },
  };
});

export default function Dyslexia() {
  const [searchQuery, setSearchQuery] = useState("");
  const [editorState, setEditorState] = useState<string | null>(null);
  const [isCreateReadDialogIsOpen, setIsCreateReadDialogIsOpen] =
    useState<boolean>(false);
  const [readTitle, setReadTitle] = useState<string>("");
  const [selectedRead, setSelectedRead] = useState(null);
  const [renameTitle, setRenameTitle] = useState<string>("");
  const [deleteReadId, setDeleteReadId] = useState<string | null>(null);
  const [isTalking, setIsTalking] = useState<boolean | null>(false);

  const { uid } = useUser();
  const firestore = useFirestore();
  const readsCollection = collection(firestore, "users", uid!, "reads");
  const readsQuery = query(readsCollection, orderBy("updatedAt", "desc"));

  // ReactFire!
  const { status, data: reads } = useFirestoreCollectionData(readsQuery, {
    idField: "id",
  });

  function openCreateRead() {
    setIsCreateReadDialogIsOpen(true);
  }

  function closeCreateRead() {
    setReadTitle("");
    setIsCreateReadDialogIsOpen(false);
  }

  function createRead() {
    addDoc(readsCollection, {
      title: readTitle,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
    });
    setReadTitle("");
    setIsCreateReadDialogIsOpen(false);
  }

  let read = reads?.find((read) => read.id === selectedRead);

  function rename() {
    if (renameTitle.length > 0 && read?.title !== renameTitle) {
      updateDoc(doc(readsCollection, read?.id), {
        title: renameTitle,
      });
    }
  }

  function save() {
    updateDoc(doc(readsCollection, read?.id), {
      content: editorState,
    });
  }

  function deleteRead() {
    setSelectedRead(null);
    deleteDoc(doc(readsCollection, deleteReadId!));
    setDeleteReadId(null);
  }

  function download() {
    try {
      let container = document.querySelector("#content");
      let article = document.querySelector(".editor-inner");
      let printArea = document.querySelector("#article");
      if (container && article) {
        container.innerHTML = article.innerHTML;

        (window as any)
          .html2pdf()
          .set({ filename: `${read?.title || "Reads"} - ReadBest.pdf` })
          .from(printArea)
          .save();
      }
    } catch (e) {}
  }

  function print() {
    try {
      let container = document.querySelector("#content");
      let article = document.querySelector(".editor-inner");
      let printArea = document.querySelector("#article");
      if (container && article) {
        container.innerHTML = article.innerHTML;
        let newWindow = window.open("", "PRINT", "height=600,width=800");
        var styleTags = document.head.querySelectorAll("style");

        // Iterate through each style tag and copy its content to the print window

        styleTags.forEach(function (styleTag) {
          if (newWindow && styleTag) {
            var newStyleTag = newWindow.document.createElement("style");
            newStyleTag.innerHTML = styleTag.innerHTML;
            newWindow.document.head.appendChild(newStyleTag);
          }
        });

        var linkTags = document.head.querySelectorAll('link[rel="stylesheet"]');
        linkTags.forEach(function (linkTag) {
          var newLinkTag = newWindow?.document.createElement("link");
          if (newWindow && newLinkTag) {
            newLinkTag.rel = "stylesheet";
            newLinkTag.href = (linkTag as any).href!;
            newWindow.document.head.appendChild(newLinkTag);
          }
        });

        if (newWindow) {
          newWindow.document.head.innerHTML += `<style>* { font-family: 'convert-core-style-dyslx' !important; }</style>`;
          newWindow.document.body.innerHTML = printArea?.innerHTML!;
          newWindow?.document.close();
          newWindow?.focus();
          newWindow?.print();
        }
      }
    } catch (e) {}
  }

  function talkALoud() {
    let article = document.querySelector(".editor-inner")?.textContent;
    if (article) {
      readText(article, {
        onStart: () => {
          setIsTalking(true);
        },
        onEnd: () => {
          setIsTalking(false);
        },
      });
    }
  }

  useEffect(() => {
    stopTalking();
  }, [selectedRead]);

  return (
    <MainLayout>
      <Box pt={4}>
        <Box>
          <Typography pb={1} variant="h5" textAlign="center" fontWeight={500}>
            Reads
          </Typography>
          <Typography variant="body1" pb={4}>
            "Reads" is your personalized note-taking experience, designed with
            dyslexia in mind. Choose dyslexia-friendly fonts, customize colors,
            and enjoy an interactive editor. With text-to-speech, visual guides,
            and collaboration options, "Reads" empowers you to read and create
            Reads effortlessly. It's not just a tool; it's inclusivity in
            action. Join us in transforming the reading experience for everyone.
          </Typography>
        </Box>

        <Divider />
        <Box>
          <Grid container spacing={2}>
            <Grid item md={3} sm={2} xs={12}>
              <Box
                pt={3}
                pr={2}
                sx={{ minHeight: "80vh", borderRight: "1px solid #eee" }}
              >
                <CreateARead onCreate={openCreateRead} onUplodad={() => {}} />
                <Search
                  value={searchQuery}
                  onChange={(value) => setSearchQuery(value)}
                />
                <List sx={{ width: "100%", pt: 0 }}>
                  {reads
                    ?.filter((read) =>
                      read.title
                        .toUpperCase()
                        .includes(searchQuery.toUpperCase())
                    )
                    ?.map((read) => {
                      return (
                        <ListItem
                          button
                          sx={{
                            mt: 2,
                            px: 4,
                            borderRadius: 8,
                            bgcolor:
                              selectedRead === read.id ? grey[200] : "inherit",
                          }}
                          onClick={() => {
                            if (selectedRead !== read.id) {
                              setSelectedRead(read.id);
                              setRenameTitle(read.title);
                              setEditorState(read.content || null);
                            }
                          }}
                        >
                          <ListItemText
                            primary={read.title}
                            secondary={`${dayjs(
                              read.updatedAt.toDate()
                            ).fromNow()}`}
                          ></ListItemText>
                        </ListItem>
                      );
                    })}
                </List>
              </Box>
            </Grid>
            <Grid item md={9} sm={10} xs={12}>
              {selectedRead ? (
                <Box p={1} pt={4} key={selectedRead}>
                  <Box px={1} display="flex" justifyContent="space-between">
                    <Box flex={1} pr={2}>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          if (document.activeElement instanceof HTMLElement) {
                            document.activeElement?.blur();
                          }
                        }}
                      >
                        <TextField
                          fullWidth
                          variant="outlined"
                          onBlur={rename}
                          onChange={({ target: { value } }) =>
                            setRenameTitle(value)
                          }
                          value={renameTitle}
                          InputProps={{}}
                          sx={{
                            border: "none",
                            "& ::before": {
                              border: "none",
                            },
                          }}
                        />
                      </form>
                    </Box>
                    <Box display={"flex"}>
                      <Box mx={1}>
                        <Button onClick={save} startIcon={<SaveOutlined />}>
                          Save
                        </Button>
                      </Box>
                      <Box mx={1}>
                        <Button
                          startIcon={<DeleteForeverOutlined />}
                          onClick={() => setDeleteReadId(selectedRead)}
                        >
                          Delete
                        </Button>
                      </Box>
                      <Box mx={1}>
                        <Button
                          startIcon={
                            isTalking ? (
                              <StopCircleOutlined />
                            ) : (
                              <RecordVoiceOverOutlined />
                            )
                          }
                          onClick={isTalking ? stopTalking : talkALoud}
                          color={isTalking ? "error" : "primary"}
                        >
                          {isTalking ? "Stop talking" : "Talk aloud"}
                        </Button>
                      </Box>
                      <Box mx={1}>
                        <Button startIcon={<PrintOutlined />} onClick={print}>
                          Print
                        </Button>
                      </Box>
                      <Box mx={1}>
                        <Button
                          startIcon={<PictureAsPdfOutlined />}
                          onClick={download}
                        >
                          Export
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                  <Editor
                    key={selectedRead}
                    onChange={(editorState: any) => {
                      const editorStateJSON = editorState.toJSON();
                      setEditorState(JSON.stringify(editorStateJSON));
                    }}
                    editorState={editorState}
                  />
                </Box>
              ) : (
                <Box
                  display="flex"
                  height={400}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="body1" color={grey[400]}>
                    Select a read
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>

      {isCreateReadDialogIsOpen && (
        <Dialog
          fullWidth
          open={isCreateReadDialogIsOpen}
          onClose={closeCreateRead}
        >
          <DialogTitle>Create a read</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              id="name"
              label="Title"
              type="text"
              fullWidth
              variant="standard"
              value={readTitle}
              onChange={({ target: { value } }) => setReadTitle(value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeCreateRead}>Cancel</Button>
            <Button onClick={createRead}>Create</Button>
          </DialogActions>
        </Dialog>
      )}

      {deleteReadId && (
        <Dialog open={!!deleteReadId} onClose={() => setDeleteReadId(null)}>
          <DialogTitle>Delete</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This read will be permanently deleted.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteReadId(null)}>Cancel</Button>
            <Button onClick={deleteRead}>Delete</Button>
          </DialogActions>
        </Dialog>
      )}

      {read != null && (
        <Box sx={{ display: "none" }}>
          <Box id="article" m={4}>
            <Typography variant="h5" pb={4} className="dyslexia">
              {read?.title || "Read"}
            </Typography>

            <Box my={1}>
              <Divider />
            </Box>
            <div id="content"></div>
            <Box mt={4} mb={2}>
              <Divider />
            </Box>
            <Box>
              <Typography variant="caption">
                Generated using <b>ReadBest Reads</b>.
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </MainLayout>
  );
}
