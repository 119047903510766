"use client";
import { Article, FileUpload, LinkOutlined } from "@mui/icons-material";
import {
  Box,
  TextField as MuiTextField,
  ToggleButton,
  ToggleButtonGroup,
  styled,
} from "@mui/material";
import { grey } from "@mui/material/colors";

export enum InputType {
  TEXT,
  LINK,
  FILE,
}

const TextField = styled(MuiTextField)(({ theme }) => {
  return {
    border: 'none',
    "& .MuiOutlinedInput-root": {
      borderColor: "transparent",
      // alignItems: "baseline",
      "&.Mui-focused fieldset": {
        borderColor: "transparent", // Border color when focused
      },
      "&:hover fieldset": {
        borderColor: "transparent", // Border color on hover
      },
      "& fieldset": {
        border: 'none !important',
        borderColor: "transparent", // Default border color
      },
    },
  };
});

export default function URL({
  value,
  onChange,
  inputType,
  disabled
}: {
  value?: string;
  onChange?: (value: string) => void;
  inputType: InputType;
  disabled?: boolean
}) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="stretch"
      width="100%"
    >
      
      <Box sx={{ flex: 1 }} pt={1}>
        <TextField
          // value={searchValue}
          // onChange={(e) => setSearchValue(e.target.value)}
          value={value}
          onChange={({ target: { value } }) => onChange?.(value)}
          fullWidth
          sx={{
            bgcolor: grey[100],
            borderRadius: 1,
            borderColor: "transparent",
            px: 2,
          }}
          multiline={inputType === InputType.TEXT}
          minRows={10}
          placeholder={inputType === InputType.TEXT ? "Write something...":"URL"}
          disabled={disabled}
          // InputProps={{
          //   startAdornment: (

          //     <InputAdornment position="start">
          //       <Link sx={{ color: "text.primary" }} />
          //     </InputAdornment>
          //   ),
          // }}
        />
      </Box>
    </Box>
  );
}
