import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "../../style.css";

export default function HeroSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width:767px)");

  return (
    <Grid display="flex" alignItems={"center"} container>
      <Grid
        display="flex"
        flexDirection={"column"}
        item
        lg={6}
        md={6}
        sm={12}
        mb={6}
        justifyContent={"center"}
      >
        <Box className="animated-text-container">
          <Carousel
            width={isMobile ? "100vw" : "100%"}
            className="carousel"
            autoPlay={true}
            showStatus={false}
            showThumbs={false}
            infiniteLoop={true}
            showArrows={false}
            interval={2000}
            renderItem={(children) => {
              return <Box mb={6}>{children}</Box>;
            }}
            renderIndicator={(onClick, isSelected) => {
              return (
                <Box
                  display="flex"
                  mx={0.2}
                  mt={10}
                  sx={{
                    borderRadius: 8,
                    width: isSelected ? 24 : 8,
                    transition: "all 1s",
                    height: 8,
                    backgroundColor: isSelected
                      ? theme.palette.primary.main
                      : "white",
                    border: `1px solid ${theme.palette.primary.main}`,
                  }}
                ></Box>
              );
            }}
          >
            <Box>
              <Typography
                className="animated-text"
                variant="h4"
                fontWeight={"600"}
                mt={2}
                mb={2}
              >
                Transform everything
              </Typography>
              <Typography
                variant="subtitle1"
                color="#637381"
                className="animated-text"
              >
                Make reads by adding text, image, PDFs and documents.
              </Typography>
            </Box>
            <Box>
              <Typography
                className="animated-text"
                variant="h4"
                fontWeight={"600"}
                mt={2}
                mb={2}
              >
                This is made for you
              </Typography>
              <Typography
                variant="subtitle1"
                color="#637381"
                className="animated-text"
              >
                Save your reads as text. Export as PDFs, documents or play as an
                audio easily.
              </Typography>
            </Box>
            <Box>
              <Typography
                className="animated-text"
                variant="h4"
                fontWeight={"600"}
                mt={2}
                mb={2}
              >
                Summarize any Article
              </Typography>
              <Typography
                variant="subtitle1"
                color="#637381"
                className="animated-text"
              >
                Enhance your reading experience with the help of our AI powered
                tool.
              </Typography>
            </Box>
          </Carousel>
        </Box>
      </Grid>
      <Grid item lg={6} md={6} sm={12} width={"100%"}>
        <Box
          display="flex"
          justifyContent={"center"}
          alignItems={"center"}
          className="animated-text-container"
        >
          <img
            className="animated-img"
            src="images/icons/home-page-icon.svg"
            alt=""
            height={isMobile ? 250 : 500}
            width={isMobile ? 250 : 500}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
