import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { updateProfile } from "firebase/auth";
import MuiPhoneNumber from "mui-phone-number";
import React, { useState } from "react";
import { useAuth, useFirestore, useFirestoreCollectionData } from "reactfire";
import useUser from "../../../../providers/user_provider/user_provider";
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  query,
  updateDoc,
} from "firebase/firestore";
import { useFeedback } from "../../../../providers/feedback/feeedback";

interface PersonalInfoInterface {
  setUpdateDetails: (val: boolean) => void;
  updateDetails: boolean;
  userFullName: string | null;
  setUserFullName: (val: string) => void;
  userEmail: string | null;
  setUserEmail: (val: string) => void;
  setUserMobileNumber: (val: string) => void;
  userMobileNumber: string | null;
  ref: any;
}

function PersonalInfo(props: any, ref: any) {
  const {
    setUpdateDetails,
    updateDetails,
    userFullName,
    setUserFullName,
    userEmail,
    setUserEmail,
    setUserMobileNumber,
    userMobileNumber,
  }: PersonalInfoInterface = props;
  const firestore = useFirestore();
  const { uid } = useUser();
  const auth = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const { fullName, mobileNumber, setFullName, setMobileNumber } = useUser();
  const { showSnackbar } = useFeedback();

  const personalDetailsCollection = collection(
    firestore,
    "users",
    auth?.currentUser?.uid!,
    "personalDetails"
  );

  const personalInfoCollection = collection(
    firestore,
    "users",
    uid!,
    "personalDetails"
  );

  const personalDetailsQuery = query(personalInfoCollection);
  const { data: personalDetails } = useFirestoreCollectionData(
    personalDetailsQuery,
    {
      idField: "id",
    }
  );

  async function updatePersonalDetails() {
    setIsLoading(true);
    await updateProfile(auth.currentUser!, {
      displayName: userFullName,
    });
    addPersonalDetails();
    setIsLoading(false);
    setFullName(userFullName!);
    setUpdateDetails(false);
  }

  async function addPersonalDetails() {
    if (personalDetails.length !== 0) {
      const docRef = doc(personalDetailsCollection, personalDetails[0].id);
      await updateDoc(docRef, {
        mobileNumber: userMobileNumber,
      });
    } else {
      const usersCollection = collection(
        firestore,
        "users",
        uid!,
        "personalDetails"
      );
      await addDoc(usersCollection, {
        mobileNumber: userMobileNumber,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
      });
    }
    showSnackbar("Details updated successfully");
    setMobileNumber(userMobileNumber);
  }

  return (
    <Grid item lg={6} md={6} sm={12} mb={3} mt={10} width="100%">
      <Box
        display="flex"
        flexDirection={"row"}
        justifyContent={"space-between"}
        ref={ref}
      >
        <Typography variant="h5">Personal details</Typography>
        <Button
          onClick={() => {
            setUpdateDetails(!updateDetails);
            if (updateDetails) {
              setUserFullName(fullName!);
              setUserMobileNumber(mobileNumber!);
            }
          }}
        >
          {updateDetails ? "Cancel" : "Update details"}
        </Button>
      </Box>
      <Box mt={2}>
        <Typography my={1}>Full name</Typography>
        <TextField
          disabled={!updateDetails}
          variant="outlined"
          placeholder="Enter your full name"
          fullWidth
          value={userFullName}
          onChange={(e) => {
            setUserFullName(e.target.value);
          }}
        />
        <Typography mt={2}>Email address</Typography>
        <TextField
          disabled={true}
          placeholder="Enter your email address"
          fullWidth
          value={userEmail}
          sx={{ mt: 1 }}
          onChange={(e) => {
            setUserEmail(e.target.value);
          }}
          FormHelperTextProps={{ sx: { color: "red" } }}
        />
        <Typography mt={2}>Mobile number</Typography>
        <MuiPhoneNumber
          value={userMobileNumber}
          variant="outlined"
          defaultCountry={"ca"}
          fullWidth
          onChange={(e) => {
            setUserMobileNumber(e.toString().trim());
          }}
          sx={{ mt: 1 }}
          onlyCountries={["ca"]}
          placeholder="Enter your mobile number"
          disabled={!updateDetails}
        />
      </Box>
      {isLoading ? (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          m={2}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          display="flex"
          mt={3}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Button
            disabled={
              !updateDetails ||
              (userFullName && userMobileNumber?.length! > 1 ? false : true)
            }
            variant="contained"
            sx={{ minWidth: 150 }}
            onClick={updatePersonalDetails}
          >
            Save changes
          </Button>
        </Box>
      )}
    </Grid>
  );
}

export default React.forwardRef(PersonalInfo);
