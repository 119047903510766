import { Add, Upload, UploadOutlined } from "@mui/icons-material";
import {
  ButtonGroup,
  ButtonProps,
  Button as MUIButton,
  Slider,
  SliderProps,
  styled,
  useTheme,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import React from "react";

const Button = styled(MUIButton)<ButtonProps>(({ theme }) => ({
  backgroundColor: "white",
  color: theme.palette.text.primary,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
}));

export default function CreateARead({
  onCreate,
  onUplodad,
}: {
  onCreate: () => void;
  onUplodad: () => void;
}) {
  const {shadows} = useTheme();
  return (
    <ButtonGroup
      variant="outlined"
      aria-label="outlined button group"
      sx={{ width: "100%",boxShadow: shadows[4], borderRadius: 24,mb: 2 }}
    >
      <Button
        size="large"
        sx={{ width: "100%",  p: 3, borderRadius: 24 }}
        variant="contained"
        startIcon={<Add />}
        onClick={onCreate}
      >
        Create
      </Button>
      {/* <Button
        size="large"
        sx={{ width: "100%", mb: 2, p: 3, borderRadius: 24 }}
        variant="contained"
        startIcon={<UploadOutlined />}
        onClick={onUplodad}
      >
        Upload
      </Button> */}
    </ButtonGroup>
  );
}
