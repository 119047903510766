import { ArticleOutlined, BookmarksOutlined } from "@mui/icons-material";
import { Box, Divider, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import "react-quill/dist/quill.snow.css";
import MainLayout from "../../layouts/main/main_layout";
import Bookmarks from "./components/bookmarks/bookmarks";
import Summarizer from "./components/summarizer/summarizer";

export default function Dyslexia() {
  let [selectedTab, setSelectedTab] = useState<Number>(0);

  function selectBookmarksTab() {
    setSelectedTab(1);
  }

  return (
    <MainLayout>
      <Box pt={4}>
        <Box>
          <Typography pb={1} variant="h5" textAlign="center" fontWeight={500}>
            Summarizer
          </Typography>
          <Typography variant="body1" pb={4}>
          Effortlessly transform any article or URL into a concise format using our powerful summarizer tool. This tool adeptly streamlines complex information, delivering quick and insightful overviews with effectiveness.
          </Typography>
        </Box>

        <Box py={4}>
          <Tabs
            centered
            sx={{ width: "100%" }}
            value={selectedTab}
            onChange={(e, index) => setSelectedTab(index)}
            aria-label="icon tabs example"
          >
            <Tab
              label="Summarizer"
              icon={<ArticleOutlined />}
              iconPosition="start"
              aria-label="phone"
            />
            <Tab
              label="Bookmarks"
              icon={<BookmarksOutlined />}
              iconPosition="start"
              aria-label="favorite"
            />
          </Tabs>

          <Divider />
          {selectedTab == 0 && (
            <Summarizer selectBookmarksTab={selectBookmarksTab} />
          )}
          {selectedTab == 1 && <Bookmarks />}
        </Box>
      </Box>
    </MainLayout>
  );
}
