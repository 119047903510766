import React from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { ArrowForward, Email, LocationOn, Phone } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();
  return (
    <Box>
      <Box mt={4}>
        <Grid container rowSpacing={2}>
          <Grid item lg={4} md={4} sm={12}>
            <Box>
              <img
                height={"50"}
                width={"fit-content"}
                src="images/icons/readbest.png"
                alt=""
              />
            </Box>
            <a
              href="https://readbest.ca/"
              color="#637381"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <Typography variant="caption" color="#637381" pl={0.5}>
                READ HELP TECHNOLOGIES INC
              </Typography>
            </a>
          </Grid>
          <Grid item lg={2} md={2} sm={12}></Grid>

          <Grid display={"flex"} flexDirection="row" item lg={6} md={6} sm={12}>
            <Box>
              <Typography variant="subtitle1">Quick Links</Typography>
              <Box
                display={"flex"}
                flexDirection={"row"}
                mt={2}
                onClick={() => navigate("/home")}
                sx={{ cursor: "pointer" }}
              >
                <ArrowForward sx={{ color: "primary.main" }} />
                <Typography variant="subtitle2" color="#637381" ml={2}>
                  Home
                </Typography>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"row"}
                mt={2}
                onClick={() => navigate("/adhd/summarizer")}
                sx={{ cursor: "pointer" }}
              >
                <ArrowForward sx={{ color: "primary.main" }} />
                <Typography variant="subtitle2" color="#637381" ml={2}>
                  ADHD
                </Typography>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"row"}
                mt={2}
                onClick={() => navigate("/dyslexia/reads")}
                sx={{ cursor: "pointer" }}
              >
                <ArrowForward sx={{ color: "primary.main" }} />
                <Typography variant="subtitle2" color="#637381" ml={2}>
                  Dyslexia
                </Typography>
              </Box>
            </Box>
            <Box ml={10}>
              <Typography variant="subtitle1">Address & Contact</Typography>
              <Box
                display={"flex"}
                flexDirection={"row"}
                mt={2}
                onClick={() => {}}
              >
                <LocationOn sx={{ color: "primary.main" }} />
                <Typography
                  variant="subtitle2"
                  color="#637381"
                  ml={2}
                  textTransform="uppercase"
                >
                  C/O Tacenda capital (VCC) INC. Waterfront Business center 145
                  chandwick ct #220 north vancouver bc v7m 3k2 canada
                </Typography>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"row"}
                mt={2}
                onClick={() => {}}
              >
                <Phone sx={{ color: "primary.main" }} />

                <a
                  style={{ textDecoration: "none" }}
                  href="mailto:+1 (236) 360-7323"
                >
                  <Typography variant="subtitle2" color="#637381" ml={2}>
                    +1 (236) 360-7323
                  </Typography>
                </a>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"row"}
                mt={2}
                onClick={() => {}}
              >
                <Email sx={{ color: "primary.main" }} />

                <a
                  style={{ textDecoration: "none" }}
                  href="mailto:info@readbest.ca"
                >
                  <Typography variant="subtitle2" color="#637381" ml={2}>
                    info@readbest.ca
                  </Typography>
                </a>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box height={70} width="100%" mt={8}>
        <Divider />
        <Box
          display="flex"
          height="100%"
          justifyContent={"center"}
          alignItems="center"
        >
          <Typography variant="subtitle2" color="#637381" textAlign={"center"}>
            Copyright 2023 © All Right Reserved Design by READ HELP TECHNOLOGIES
            INC
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
