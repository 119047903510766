import {
  DeleteOutlined,
  PictureAsPdfOutlined,
  PrintOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import dayjs from "dayjs";
import { collection, deleteDoc, doc, orderBy, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import Search from "../../../../components/search/search";
import URL, { InputType } from "../../../../components/url/url";
import useUser from "../../../../providers/user_provider/user_provider";

export default function Bookmarks() {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedSummary, setSelectedSummary] = useState(null);
  const [deleteBookmarkId, setDeleteBookmarkId] = useState<string | null>(null);
  const { uid } = useUser();
  const firestore = useFirestore();
  const summarizersCollection = collection(
    firestore,
    "users",
    uid!,
    "summarizers"
  );
  const summarizerQuery = query(
    summarizersCollection,
    orderBy("updatedAt", "desc")
  );

  // ReactFire!
  const {
    status,
    isComplete,
    data: summarizers,
  } = useFirestoreCollectionData(summarizerQuery, {
    idField: "id",
  });

  useEffect(() => {
    if (selectedSummary == null && status === "success" && summarizers.length > 0) {
      setSelectedSummary(summarizers[0]?.id);
    }
  }, [summarizers, selectedSummary, isComplete,status]);

  let summary = summarizers?.find((summary) => summary.id === selectedSummary);

  function getInputType(type: string): InputType {
    switch (type) {
      case "TEXT":
        return InputType.TEXT;
      case "LINK":
        return InputType.LINK;
      case "FILE":
        return InputType.FILE;
    }
    return InputType.TEXT;
  }

  function deleteBookmark() {
    setSelectedSummary(null);
    deleteDoc(doc(summarizersCollection, deleteBookmarkId!));
    setDeleteBookmarkId(null);
  }

  function download() {
    try {
      (window as any)
        .html2pdf()
        .set({ filename: "Summary - ReadBest.pdf" })
        .from(document.getElementById("article"))
        .save();
    } catch (e) {}
  }

  function print() {
    try {
      let article = document.getElementById("article");
      let newWindow = window.open("", "PRINT", "height=400,width=600");
      var styleTags = document.head.querySelectorAll("style");

      // Iterate through each style tag and copy its content to the print window

      styleTags.forEach(function (styleTag) {
        if (newWindow && styleTag) {
          var newStyleTag = newWindow.document.createElement("style");
          newStyleTag.innerHTML = styleTag.innerHTML;
          newWindow.document.head.appendChild(newStyleTag);
        }
      });

      var linkTags = document.head.querySelectorAll('link[rel="stylesheet"]');
      linkTags.forEach(function (linkTag) {
        var newLinkTag = newWindow?.document.createElement("link");
        if (newWindow && newLinkTag) {
          newLinkTag.rel = "stylesheet";
          newLinkTag.href = (linkTag as any).href!;
          newWindow.document.head.appendChild(newLinkTag);
        }
      });

      if (newWindow) {
        newWindow.document.head.innerHTML += `<style>* { font-family: 'Public Sans', sans-serif !important; }</style>`;
        newWindow.document.body.innerHTML += (article as any)?.innerHTML;
        newWindow?.document.close();
        newWindow?.focus();
        newWindow?.print();
      }
      // newWindow?.close();
    } catch (e) {}
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={3} sm={2} xs={12}>
        <Box
          pr={2}
          pt={2}
          sx={{ minHeight: "80vh", borderRight: "1px solid #eee" }}
        >
          <Search
            value={searchQuery}
            onChange={(value) => setSearchQuery(value)}
          />
          <List sx={{ width: "100%", pt: 2 }}>
            {summarizers?.length > 0 ? (
              summarizers
                ?.filter((summary) =>
                  summary.title
                    .toUpperCase()
                    .includes(searchQuery.toUpperCase())
                )
                ?.map((summary, index) => {
                  return (
                    <ListItem
                      button
                      sx={{
                        mt: 2,
                        px: 4,
                        borderRadius: 8,
                        bgcolor:
                          selectedSummary === summary?.id
                            ? grey[200]
                            : "inherit",
                      }}
                      onClick={() => {
                        setSelectedSummary(summary?.id);
                      }}
                    >
                      <ListItemText
                        primary={summary.title}
                        secondary={dayjs(summary.updatedAt.toDate()).fromNow()}
                      ></ListItemText>
                    </ListItem>
                  );
                })
            ) : (
              <Box
                display="flex"
                height={400}
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="body2" color={grey[400]}>
                  No bookmarks
                </Typography>
              </Box>
            )}
          </List>
        </Box>
      </Grid>
      <Grid item md={9} sm={10} xs={12}>
        {summary != null ? (
          <Box p={1}>
            <Box py={1} px={1} display="flex" justifyContent="space-between">
              <Typography variant="h6">{summary.title}</Typography>
              <Box display={"flex"}>
                <Box mx={1}>
                  <Button startIcon={<PrintOutlined />} onClick={print}>
                    Print
                  </Button>
                </Box>
                <Box mx={1}>
                  <Button
                    startIcon={<PictureAsPdfOutlined />}
                    onClick={download}
                  >
                    Export
                  </Button>
                </Box>
                <Box mx={1}>
                  <Button
                    startIcon={<DeleteOutlined />}
                    onClick={() => setDeleteBookmarkId(summary?.id!)}
                  >
                    Delete
                  </Button>
                </Box>
              </Box>
            </Box>

            <Box pt={1}>
              <URL
                inputType={getInputType(summary.type)}
                disabled={true}
                value={summary.input}
              />
            </Box>
            <Box>
              <Box mt={4} mr={4}>
                <Box
                  bgcolor={blue[50]}
                  border={`2px dashed ${blue[400]}`}
                  borderRadius={1}
                  p={4}
                  pt={3}
                  width={"100%"}
                >
                  <Typography variant="h6">Article Summary</Typography>
                  <Typography variant="body1" pt={2}>
                    {summary.summary}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            display="flex"
            height={400}
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="body1" color={grey[400]}>
              Select a bookmark
            </Typography>
          </Box>
        )}

        {deleteBookmarkId && (
          <Dialog
            open={!!deleteBookmarkId}
            onClose={() => setDeleteBookmarkId(null)}
          >
            <DialogTitle>Delete</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This bookmark will be permanently deleted.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDeleteBookmarkId(null)}>Cancel</Button>
              <Button onClick={deleteBookmark}>Delete</Button>
            </DialogActions>
          </Dialog>
        )}

        {summary != null && (
          <Box sx={{ display: "none" }}>
            <Box id="article" m={4}>
              <Typography variant="h5" pb={4}>
                {summary.title || "Article Summary"}
              </Typography>
              <Typography variant="body1">{summary.summary || ""}</Typography>
              <Box my={4}>
                <Divider />
              </Box>
              <Typography variant="body1" pb={2}>
                Original Article
              </Typography>
              <Typography variant="body2">{summary.input || ""}</Typography>

              <Box mt={4} mb={2}>
                <Divider />
              </Box>
              <Box>
                <Typography variant="caption">
                  Generated using <b>ReadBest Summarizer</b>.
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}
