import React from "react";
import MainLayout from "../../layouts/main/main_layout";
import { Box } from "@mui/material";

export default function AccessDenied() {
  return (
    <MainLayout>
      <Box
        display="flex"
        height="100vh"
        justifyContent={"center"}
        alignItems={"center"}
      >
        <img
          src="images/icons/no-access.svg"
          alt=""
          style={{ height: 500, width: 500 }}
        />
      </Box>
    </MainLayout>
  );
}
