import React, { useEffect, useRef, useState } from "react";
import MainLayout from "../../layouts/main/main_layout";
import {
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import useUser from "../../providers/user_provider/user_provider";
import { Key, Logout, PersonOutlined } from "@mui/icons-material";
import { useFeedback } from "../../providers/feedback/feeedback";
import { signOut } from "firebase/auth";
import { useAuth } from "reactfire";
import { useNavigate } from "react-router-dom";
import PersonalInfo from "./components/personal_info/personal_info";
import ChangePassword from "./components/change_password/change_password";

export default function Profile() {
  const { fullName, profilePic, email, mobileNumber } = useUser();
  const [userFullName, setUserFullName] = useState<string | null>(fullName);
  const [userEmail, setUserEmail] = useState<string | null>(email);
  const [updateDetails, setUpdateDetails] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const isMobile = useMediaQuery("(max-width:767px)");

  const personalInfoRef = useRef<any>();
  const changePassRef = useRef<any>();

  const auth = useAuth();
  const { showSnackbar } = useFeedback();
  const navigate = useNavigate();

  const devider = () => {
    return (
      <Divider
        style={{
          marginTop: 24,
          width: "100%",
          borderBottom: "1px dashed rgba(145, 158, 171, 0.24)",
        }}
      />
    );
  };

  console.log(fullName, "name");

  function _signOut() {
    signOut(auth);
    showSnackbar("Logout successfully");
    navigate("/");
  }
  const [userMobileNumber, setUserMobileNumber] = useState<string | null>(
    mobileNumber
  );

  const handleScroll = (ref: any) => {
    if (ref?.current && isMobile) {
      ref?.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const profileDetails = () => {
    switch (selectedItem) {
      case "Personal Details":
        return (
          <PersonalInfo
            userMobileNumber={userMobileNumber}
            setUserMobileNumber={setUserMobileNumber}
            setUpdateDetails={setUpdateDetails}
            setUserEmail={setUserEmail}
            setUserFullName={setUserFullName}
            updateDetails={updateDetails}
            userEmail={userEmail}
            userFullName={userFullName}
            ref={personalInfoRef}
          />
        );
      case "Change password":
        return <ChangePassword ref={changePassRef} />;

      default:
        break;
    }
  };

  useEffect(() => {
    if (selectedItem === "Personal Details") {
      handleScroll(personalInfoRef);
    } else {
      handleScroll(changePassRef);
    }
  }, [selectedItem]);

  return (
    <MainLayout>
      <Box mx={2}>
        <Grid container spacing={isMobile ? 0 : 8}>
          <Grid item lg={6} md={6} sm={12} mt={10} width="100%">
            <Box>
              <Card
                elevation={10}
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  pb: 4,
                  border: "1px solid rgba(145, 158, 171, 0.24)",
                }}
              >
                <Box mx={2}>
                  <Avatar
                    sx={{ height: 70, width: 70, mt: 2 }}
                    alt=""
                    src={profilePic!}
                  />
                  <Typography
                    mt={1}
                    fontWeight={"600"}
                    textTransform={"capitalize"}
                  >
                    {fullName}
                  </Typography>
                  <Typography color="#637381" variant="body2">
                    {email}
                  </Typography>
                  {devider()}
                  <Box display="flex" mt={3}>
                    <Button
                      onClick={() => {
                        setSelectedItem("Personal Details");
                        // handleScroll(personalInfoRef);
                      }}
                    >
                      <PersonOutlined
                        sx={{
                          color:
                            selectedItem === "Personal Details"
                              ? "primary.main"
                              : "#000000",
                        }}
                      />
                      <Typography
                        color={
                          selectedItem === "Personal Details"
                            ? "primary.main"
                            : "#000000"
                        }
                        variant="button"
                        ml={1}
                      >
                        Personal Info
                      </Typography>
                    </Button>
                  </Box>
                  <Box display="flex" mt={3}>
                    <Button
                      onClick={() => {
                        setSelectedItem("Change password");
                      }}
                    >
                      <Key
                        sx={{
                          color:
                            selectedItem === "Change password"
                              ? "primary.main"
                              : "#000000",
                        }}
                      />
                      <Typography
                        color={
                          selectedItem === "Change password"
                            ? "primary.main"
                            : "#000000"
                        }
                        variant="button"
                        ml={1}
                      >
                        Change Password
                      </Typography>
                    </Button>
                  </Box>
                  {devider()}
                  <Box display="flex" mt={3}>
                    <Button variant="text" onClick={_signOut}>
                      <Logout
                        sx={{
                          color: "#000000",
                        }}
                      />
                      <Typography variant="button" ml={1} color="#000000">
                        Logout
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Card>
            </Box>
          </Grid>
          {profileDetails()}
        </Grid>
      </Box>
    </MainLayout>
  );
}
