import React, { useEffect } from "react";
import MainLayout from "../../layouts/main/main_layout";
import { Box, useMediaQuery } from "@mui/material";
import "./style.css";
import HeroSection from "./components/hero_section/hero_section";
import OurServices from "./components/our_services_section/our_services";
import Faq from "./components/faq/faq";
import AboutUs from "./components/about_us/about_us";
import { useAuth, useFirestore, useFirestoreCollectionData } from "reactfire";
import { collection, query } from "firebase/firestore";
import useUser from "../../providers/user_provider/user_provider";

export default function Home() {
  const { setMobileNumber } = useUser();
  const isMobile = useMediaQuery("(max-width:767px)");
  const firestore = useFirestore();
  const auth = useAuth();

  const personalInfoCollection = collection(
    firestore,
    "users",
    auth.currentUser?.uid!,
    "personalDetails"
  );
  const personalDetailsQuery = query(personalInfoCollection);
  const { data: personalDetails } =
    useFirestoreCollectionData(personalDetailsQuery);

  useEffect(() => {
    setMobileNumber(personalDetails?.[0]?.mobileNumber);
  }, [personalDetails]);
  return (
    <MainLayout>
      <Box mt={8} mx={isMobile ? 2 : 0}>
        <HeroSection />
        <AboutUs />
        <OurServices />
        {/* <ChapterCard2 /> */}
        <Faq />
      </Box>
    </MainLayout>
  );
}
