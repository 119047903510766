import {
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { signOut } from "firebase/auth";
import { PropsWithChildren } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "reactfire";
import { useFeedback } from "../../providers/feedback/feeedback";
import useUser from "../../providers/user_provider/user_provider";
import Footer from "../../views/footer/footer";
import ScrollToTop from "../../components/scroll_to_top/scorll_to_top";
import { Grade, ReadMore } from "@mui/icons-material";

export default function MainLayout({ children }: PropsWithChildren) {
  const auth = useAuth();
  const { fullName, profilePic } = useUser();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { showSnackbar } = useFeedback();

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement>,
    id: string
  ) => {
    var x = document.getElementById(id);
    if (x) {
      x.style.display = "block";
    }
  };

  const handlePopoverClose = (id: string) => {
    var x = document.getElementById(id);
    if (x) {
      x.style.display = "none";
    }
  };

  async function _signOut() {
    await signOut(auth);
    showSnackbar("Logout successfully");
    navigate("/");
  }

  function _onMouseEnter() {
    var x = document.getElementById("card");
    if (x) {
      x.style.display = "block";
    }
  }
  function _onMouseLeave() {
    var x = document.getElementById("card");
    if (x) {
      x.style.display = "none";
    }
  }

  let dyslexiaItems = [
    { title: "Reads", path: "/dyslexia/reads", icon: <ReadMore /> },
    { title: "Grades", path: "/dyslexia/grades", icon: <Grade /> },
  ];
  let adhdItems = [
    { title: "Summarizer", path: "/adhd/summarizer", icon: <ReadMore /> },
    { title: "Grades", path: "/adhd/grades", icon: <Grade /> },
  ];

  return (
    <Box display="flex" height="100vh" width="100%">
      <ScrollToTop />
      <Box
        position="fixed"
        top={0}
        left={0}
        width={"100%"}
        height={80}
        sx={{
          backdropFilter: "blur(6px)",
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 1300,
        }}
      >
        <Grid container justifyContent="center" alignItems="center" height={80}>
          <Grid item md={10} sm={10} xs={12}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box display={"flex"} flexDirection={"column"} mt={2}>
                <img
                  height={50}
                  width={"100%"}
                  src={`${window.location.origin}/images/icons/readbest.png`}
                  alt=""
                  style={{ width: "fit-content" }}
                />
                <Box mt={1}>
                  <a
                    href="https://readbest.ca/"
                    color="#637381"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <Typography variant="caption" color="#637381" pl={0.5}>
                      READ HELP TECHNOLOGIES INC
                    </Typography>
                  </a>
                </Box>
              </Box>

              <Box
                flex={1}
                justifyContent="center"
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <Box p={2}>
                  <Link to="/home" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      fontWeight={pathname === "/home" ? "800" : "400"}
                    >
                      Home
                    </Typography>
                  </Link>
                </Box>
                <Link
                  style={{ textDecoration: "none" }}
                  to={"/dyslexia/reads"}
                  // onMouseEnter={(e) => handlePopoverOpen(e, "dyslexiaPopover")}
                  // onMouseLeave={() => handlePopoverClose("dyslexiaPopover")}
                >
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    fontWeight={pathname?.includes("/dyslexia") ? "800" : "400"}
                    p={2}
                  >
                    Dyslexia
                  </Typography>
                  <Box
                    // id="dyslexiaPopover"
                    sx={{
                      px: 2,
                      display: "none",
                      flexDirection: "column",
                      position: "absolute",
                      top: 40,
                      right: -50,
                    }}
                  >
                    <Card
                      elevation={10}
                      sx={{
                        px: 2,
                        flexDirection: "column",
                        height: "100%",
                      }}
                    >
                      <List
                        sx={{
                          width: "100%",
                          maxWidth: 360,
                          bgcolor: "background.paper",
                        }}
                      >
                        {dyslexiaItems?.map((items) => {
                          return (
                            <ListItem sx={{ m: 0, p: 0 }}>
                              <Button onClick={() => navigate(items?.path)}>
                                <ListItemAvatar>
                                  <Avatar>{items?.icon}</Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  primary={items?.title}
                                  primaryTypographyProps={{
                                    variant: "body2",
                                    color: "text.secondary",
                                  }}
                                />
                              </Button>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Card>
                  </Box>
                </Link>
                <Link
                  style={{ textDecoration: "none" }}
                  to="/adhd/summarizer"
                  // onMouseEnter={(e) => handlePopoverOpen(e, "adhdPopover")}
                  // onMouseLeave={() => handlePopoverClose("adhdPopover")}
                >
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    fontWeight={pathname?.includes("/adhd") ? "800" : "400"}
                    p={2}
                  >
                    ADHD
                  </Typography>
                  <Box
                    // id="adhdPopover"
                    sx={{
                      px: 2,
                      display: "none",
                      flexDirection: "column",
                      position: "absolute",
                      top: 40,
                      right: -50,
                    }}
                  >
                    <Card
                      elevation={10}
                      sx={{
                        px: 2,
                        flexDirection: "column",
                        height: "100%",
                      }}
                    >
                      <List
                        sx={{
                          width: "100%",
                          maxWidth: 360,
                          bgcolor: "background.paper",
                        }}
                      >
                        {adhdItems?.map((items) => {
                          return (
                            <ListItem sx={{ m: 0, p: 0 }}>
                              <Button onClick={() => navigate(items?.path)}>
                                <ListItemAvatar>
                                  <Avatar>{items?.icon}</Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  primary={items?.title}
                                  primaryTypographyProps={{
                                    variant: "body2",
                                    color: "text.secondary",
                                  }}
                                />
                              </Button>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Card>
                  </Box>
                </Link>
                <Link
                  style={{ textDecoration: "none" }}
                  to="/learning"
                  // onMouseEnter={(e) => handlePopoverOpen(e, "adhdPopover")}
                  // onMouseLeave={() => handlePopoverClose("adhdPopover")}
                >
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    fontWeight={pathname?.includes("/learning") ? "800" : "400"}
                    p={2}
                  >
                    Learning
                  </Typography>
                </Link>
                <Box p={2}>
                  <Link to="/profile" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      fontWeight={pathname === "/profile" ? "800" : "400"}
                    >
                      Profile
                    </Typography>
                  </Link>
                </Box>
                <Box p={2}>
                  <a
                    href="https://readbest.ca"
                    style={{ textDecoration: "none" }}
                  >
                    <Button variant="contained">ReadBest.ca</Button>
                  </a>
                </Box>
              </Box>

              <Box position="relative">
                <IconButton
                  onMouseEnter={_onMouseEnter}
                  onMouseLeave={_onMouseLeave}
                >
                  {profilePic ? (
                    <Avatar alt="" src={profilePic} />
                  ) : (
                    <Avatar>
                      <Typography textTransform={"uppercase"}>
                        {fullName?.substring(0, 1)}
                      </Typography>
                    </Avatar>
                  )}
                </IconButton>
                <Box
                  id="card"
                  position="absolute"
                  height={230}
                  width={180}
                  left={-160}
                  mt={1}
                  display={"none"}
                  onMouseEnter={_onMouseEnter}
                  onMouseLeave={_onMouseLeave}
                >
                  <Card
                    elevation={10}
                    sx={{
                      px: 2,
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    {profilePic != null ? (
                      <Avatar
                        sx={{ height: 100, width: 100, mt: 2 }}
                        alt=""
                        src={profilePic}
                      />
                    ) : (
                      <Avatar>
                        <Typography textTransform={"uppercase"}>
                          {fullName?.substring(0, 1)}
                        </Typography>
                      </Avatar>
                    )}
                    <Box width="100%">
                      <Typography
                        align="left"
                        variant="body2"
                        mt={2}
                        textOverflow={"ellipsis"}
                        whiteSpace={"nowrap"}
                        overflow={"hidden"}
                        textTransform={"capitalize"}
                      >
                        {fullName}
                      </Typography>
                    </Box>
                    <Button
                      variant="outlined"
                      sx={{ display: "flex", mt: 2, width: 150 }}
                      onClick={_signOut}
                    >
                      Logout
                    </Button>
                  </Card>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box flex={1} pt={8}>
        <Grid container justifyContent="center">
          <Grid item md={10} sm={10} xs={12}>
            {children}
          </Grid>
          <Grid md={10} sm={10} xs={12}>
            <Box mt={6} my={4}>
              <Divider />
            </Box>
            <Footer />
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Grid container justifyContent="center">
          <Grid item md={10} sm={10} xs={12}></Grid>
        </Grid>
      </Box>
    </Box>
  );
}
