import { Box, Typography } from "@mui/material";
import React from "react";

export default function AboutUs() {
  return (
    <Box>
      <Typography variant="h3" fontWeight={"600"}>
        About us
      </Typography>
      <Typography variant="h6" mt={3} color="#637381">
        Empowering Minds, Embracing Differences At Readbest, we understand the
        unique challenges that individuals with Dyslexia and ADHD face. That’s
        why we’ve created a powerful tool designed to support and enhance the
        learning experience for those with these conditions.
      </Typography>
    </Box>
  );
}
