import React from "react";
import { RouterProvider } from "react-router-dom";
import routes from "./routes";
import { ThemeProvider } from "@mui/material";
import theme from "@repo/ui/theme";
import FirebaseAuthProvider from "./firebase_auth/firebase_auth_provider";
import {
  AnalyticsProvider,
  FirestoreProvider,
  useFirebaseApp,
  useInitPerformance,
} from "reactfire";

import { UserProvider } from "./providers/user_provider/user_provider";
import { getFirestore } from "firebase/firestore";
import FeedbackProvide from "./providers/feedback/feeedback";
import { getAnalytics } from "firebase/analytics";

function App() {
  const app = useFirebaseApp();
  // const analytics = useAnalytics();
  const analytics = getAnalytics(app);

  const firestore = getFirestore(app);

  useInitPerformance(async (firebaseApp) => {
    const { getPerformance } = await import("firebase/performance");
    return getPerformance(firebaseApp);
  });

  return (
    <div className="App">
      <FeedbackProvide>
        <ThemeProvider theme={theme}>
          <AnalyticsProvider sdk={analytics}>
            <FirebaseAuthProvider>
              <FirestoreProvider sdk={firestore}>
                <UserProvider>
                  <RouterProvider router={routes} />
                </UserProvider>
              </FirestoreProvider>
            </FirebaseAuthProvider>
          </AnalyticsProvider>
        </ThemeProvider>
      </FeedbackProvide>
    </div>
  );
}

export default App;
