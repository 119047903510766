import { Box } from "@mui/material";
import React from "react";

export default function NoDataFound() {
  return (
    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
      <img
        src="../images/icons/no_data_found.svg"
        alt=""
        height={"100%"}
        width="100%"
      />
    </Box>
  );
}
