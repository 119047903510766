import { Add } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";

export default function Faq() {
  const isMobile = useMediaQuery("(max-width:767px)");
  let faq = [
    {
      question: "How does ReadBest address challenges like ADHD and dyslexia?",
      ans: "ReadBest utilizes innovative software solutions designed to cater to individual learning needs, providing targeted support for those facing challenges such as ADHD and dyslexia.",
    },
    {
      question:
        "What measures does ReadBest take to foster positivity and celebrate differences in its learning environment?",
      ans: "ReadBest is committed to creating an inclusive atmosphere by promoting positivity, celebrating diversity, and integrating materials that highlight and respect individual differences.",
    },
    {
      question:
        "How does ReadBest revolutionize education through its software solutions?",
      ans: "ReadBest leverages cutting-edge software to personalize learning experiences, adapting to each learner's pace and style. This approach ensures a transformative and effective educational journey.",
    },
    {
      question:
        "What outcomes can learners expect from ReadBest's educational approach?",
      ans: "Learners emerge with increased confidence, equipped with the necessary skills and knowledge for a brighter future. The personalized learning experience contributes to a holistic and successful educational outcome.",
    },
    {
      question:
        "How can individuals contribute to reshaping education and embracing diversity with ReadBest?",
      ans: "Joining ReadBest means actively participating in the educational revolution. Individuals can support the cause by engaging with the platform, providing feedback, and spreading awareness about the importance of diversity in education.",
    },
  ];
  return (
    <Box mt={14}>
      <Grid container>
        <Grid item lg={6} md={6} sm={12} mb={isMobile ? 4 : 12}>
          <Box>
            <Typography
              variant="subtitle2"
              color="#637381"
              sx={{ textTransform: "capitalize" }}
            >
              FAQs
            </Typography>
            <Typography variant="h3" fontWeight={"600"} mt={3}>
              Frequently Asked Questions
            </Typography>
          </Box>
          <Box mt={3}>
            {faq.map((que) => {
              return (
                <Box sx={{ my: 3 }}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<Add />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>{que?.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>{que?.ans}</Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              );
            })}
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={12} mb={isMobile ? 4 : 12} width="100%">
          <Box display="flex" justifyContent={"center"}>
            <img
              src="images/icons/faq.svg"
              alt=""
              height={isMobile ? 250 : 500}
              width={isMobile ? 250 : 500}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
