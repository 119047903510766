import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/main/main_layout";
import Search from "../../components/search/search";
import { grey } from "@mui/material/colors";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import {
  DocumentData,
  collection,
  doc,
  getDoc,
  orderBy,
  query,
  where,
} from "firebase/firestore";

import { readText, stopTalking } from "../../utils/speach_to_text";
import { useLocation, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  PictureAsPdfOutlined,
  PrintOutlined,
  RecordVoiceOverOutlined,
  StopCircleOutlined,
} from "@mui/icons-material";

export default function Chapter({ concept }: { concept: string }) {
  const { pathname } = useLocation();
  const { chapterId, gradesId } = useParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [isTalking, setIsTalking] = useState<boolean | null>(false);
  const [selectedChapter, setSelectedChapter] = useState<DocumentData | null>(
    null
  );

  const firestore = useFirestore();
  let name = "learnings";
  //pathname?.includes("adhd") ? "adhd" : "dyslexia";

  const subjectsCollection = collection(
    firestore,
    name,
    gradesId!,
    "subjects",
    chapterId!,
    "chapters"
  );

  const chapterCollectionQuery = query(
    subjectsCollection,
    orderBy("order", "asc")
  );

  const { data: chapterCollection } = useFirestoreCollectionData(
    chapterCollectionQuery,
    {
      idField: "id",
    }
  );

  useEffect(() => {
    setSelectedChapter(chapterCollection?.[0]);
  }, [chapterCollection]);

  const { data: chapterContent } = useQuery(
    ["chapter-content", chapterId, selectedChapter],
    async () => {
      const subjectsCollection = collection(
        firestore,
        name,
        gradesId!,
        "subjects",
        chapterId!,
        "chapters"
      );
      return (
        await getDoc(
          doc(firestore, subjectsCollection.path, selectedChapter?.id!)
        )
      ).data();
    },
    { staleTime: Infinity, enabled: !!selectedChapter }
  );

  const subjects = collection(firestore, name, gradesId!, "subjects");

  const subjectQuery = query(subjects, where("name", "==", "English"));

  const { data: filteredSubject } = useFirestoreCollectionData(subjectQuery);

  function download() {
    try {
      let container = document.querySelector("#content");
      let article = document.querySelector("#chapter");
      let printArea = document.querySelector("#article");

      if (container && article) {
        container.innerHTML = article.innerHTML;

        (window as any)
          .html2pdf()
          .set({
            filename: `${selectedChapter?.name} - Subject-${filteredSubject?.[0]?.name}.pdf`,
          })
          .from(printArea)
          .save();
      }
    } catch (e) {
      console.log(e);
    }
  }

  function print() {
    try {
      let container = document.querySelector("#content");
      let article = document.querySelector("#chapter");
      let printArea = document.querySelector("#article");
      if (container && article) {
        if (article) {
          container.innerHTML = article.innerHTML;
          let newWindow = window.open("", "PRINT", "height=600,width=800");
          var styleTags = document.head.querySelectorAll("style");

          styleTags.forEach(function (styleTag) {
            if (newWindow && styleTag) {
              var newStyleTag = newWindow.document.createElement("style");
              newStyleTag.innerHTML = styleTag.innerHTML;
              newWindow.document.head.appendChild(newStyleTag);
            }
          });

          var linkTags = document.head.querySelectorAll(
            'link[rel="stylesheet"]'
          );
          linkTags.forEach(function (linkTag) {
            var newLinkTag = newWindow?.document.createElement("link");
            if (newWindow && newLinkTag) {
              newLinkTag.rel = "stylesheet";
              newLinkTag.href = (linkTag as any).href!;
              newWindow.document.head.appendChild(newLinkTag);
            }
          });

          if (newWindow) {
            newWindow.document.head.innerHTML += `<style>* { font-family: 'convert-core-style-dyslx' !important; }</style>`;
            newWindow.document.body.innerHTML = printArea?.innerHTML!;
            newWindow?.document.close();
            newWindow?.focus();
            newWindow?.print();
          }
        }
      }
    } catch (e) {}
  }

  function talkALoud() {
    let article = document.querySelector(".elementor-container")?.textContent;

    if (article) {
      readText(article, {
        onStart: () => {
          setIsTalking(true);
        },
        onEnd: () => {
          setIsTalking(false);
        },
      });
    }
  }

  useEffect(() => {
    stopTalking();
  }, [selectedChapter]);

  return (
    <MainLayout>
      <Box pt={4}>
        <Box>
          <Typography pb={1} variant="h5" textAlign="center" fontWeight={500}>
            {filteredSubject?.[0]?.name}
          </Typography>
          <Typography variant="body1" pb={4} id="desription">
            {filteredSubject?.[0]?.description}
          </Typography>
        </Box>

        <Divider />
        <Box>
          <Grid container spacing={2}>
            <Grid item md={3} sm={2} xs={12}>
              <Box
                pt={3}
                pr={2}
                sx={{ minHeight: "80vh", borderRight: "1px solid #eee" }}
              >
                <Search
                  value={searchQuery}
                  onChange={(value) => setSearchQuery(value)}
                />
                <List sx={{ width: "100%", pt: 0 }}>
                  {chapterCollection
                    ?.filter((chapterContent) =>
                      chapterContent?.name
                        ?.toUpperCase?.()
                        ?.includes(searchQuery.toUpperCase())
                    )
                    ?.map((chapter) => {
                      return (
                        <ListItem
                          // disabled={!chapter?.isFreemium}
                          button
                          sx={{
                            mt: 2,
                            px: 4,
                            borderRadius: 8,
                            bgcolor:
                              selectedChapter?.id === chapter.id
                                ? grey[200]
                                : "inherit",
                          }}
                          onClick={() => {
                            setSelectedChapter(chapter);
                          }}
                          // secondaryAction={
                          //   chapter?.isFreemium ? (
                          //     <Box mb={5}>
                          //       <Typography
                          //         fontSize={10}
                          //         fontWeight={"600"}
                          //         mr={2}
                          //         sx={{
                          //           bgcolor: "#ADD8E6",
                          //           px: 2,
                          //           py: 1,
                          //           borderRadius: 1,
                          //         }}
                          //       >
                          //         Freemium
                          //       </Typography>
                          //     </Box>
                          //   ) : (
                          //     <Box
                          //       display={"flex"}
                          //       mb={2}
                          //       alignItems={"center"}
                          //       justifyContent={"center"}
                          //     >
                          //       <Typography
                          //         fontSize={10}
                          //         fontWeight={"600"}
                          //         mr={1}
                          //         sx={{
                          //           backgroundColor: "#FFD700",
                          //           px: 2,
                          //           py: 1,
                          //           borderRadius: 1,
                          //         }}
                          //       >
                          //         Premium
                          //       </Typography>

                          //       <img
                          //         src="/images/icons/premium.png"
                          //         alt=""
                          //         width={15}
                          //         height={15}
                          //       />
                          //     </Box>
                          //   )
                          // }
                        >
                          <ListItemText
                            primary={chapter?.name?.split("-")?.[0]}
                            secondary={chapter?.name?.split("-")?.[1]}
                            secondaryTypographyProps={{ mt: 1 }}
                          ></ListItemText>
                        </ListItem>
                      );
                    })}
                </List>
              </Box>
            </Grid>
            <Grid item md={9} sm={10} xs={12}>
              {chapterContent ? (
                <Box p={1} pt={4} key={selectedChapter?.id}>
                  <Box py={1} px={1} display="flex" justifyContent="flex-end">
                    <Box display={"flex"}>
                      <Box mx={1}>
                        {/* <Button
                          startIcon={
                            isTalking ? (
                              <StopCircleOutlined />
                            ) : (
                              <RecordVoiceOverOutlined />
                            )
                          }
                          onClick={isTalking ? stopTalking : talkALoud}
                          color={isTalking ? "error" : "primary"}
                        >
                          {isTalking ? "Stop talking" : "Talk aloud"}
                        </Button> */}
                      </Box>
                      {/* <Box mx={1}>
                        <Button startIcon={<PrintOutlined />} onClick={print}>
                          Print
                        </Button>
                      </Box>
                      <Box mx={1}>
                        <Button
                          startIcon={<PictureAsPdfOutlined />}
                          onClick={download}
                        >
                          Export
                        </Button>
                      </Box> */}
                    </Box>
                  </Box>
                  <Box
                    id="chapter"
                    dangerouslySetInnerHTML={{
                      __html: chapterContent?.content,
                    }}
                    fontFamily={"convert-core-style-dyslx !important"}
                  ></Box>
                </Box>
              ) : (
                <Box
                  display="flex"
                  height={400}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="body1" color={grey[400]}>
                    Select a chapter
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      {chapterContent != null && (
        <Box sx={{ display: "none" }}>
          <Box id="article" m={4}>
            <Typography variant="h5" pb={4} id="dyslexia">
              {chapterContent?.name}
            </Typography>

            <Box my={1}>
              <Divider />
            </Box>
            <div id="content"></div>
            <Box mt={4} mb={2}>
              <Divider />
            </Box>
            <Box>
              <Typography variant="caption">
                Generated using <b>ReadBest Reads</b>.
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </MainLayout>
  );
}
