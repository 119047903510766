import { East } from "@mui/icons-material";
import {
  Box,
  Card,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function OurServices() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:767px)");
  let cardList = [
    {
      title: "Dyslexia",
      description:
        "Your inclusive, dyslexia-friendly note-taking app for effortless reading and creation. Join us in transforming the reading experience.",
      img: "images/icons/dyslexia.png",
      path: "/dyslexia/reads",
    },
    {
      title: "ADHD",
      description:
        "Effortlessly Concise any article or URl with our powerful summarizer tool, streamlining information for quick insights",
      img: "images/icons/adhd.png",
      path: "/adhd/summarizer",
    },
    {
      title: "Learnings",
      description:
        "Unlock endless learning possibilities with our curated chapters and insightful content. Expand your horizons and empower your growth today.",
      img: "images/icons/learnings.png",
      path: "/learnings",
    },
  ];
  return (
    <Box mt={14}>
      <Box>
        <Typography
          variant="subtitle2"
          color="#637381"
          sx={{ textTransform: "capitalize" }}
        >
          OUR SERVICES
        </Typography>

        <Typography variant="h3" fontWeight={"600"} mt={3}>
          Find your tools here
        </Typography>
        <Typography variant="h6" mt={2} color="#637381">
          See what's possible with ReadBest
        </Typography>
      </Box>
      <Grid container spacing={4} mt={3} mb={2} justifyContent={"center"}>
        {cardList.map((cardItem) => {
          return (
            <Grid item lg={3} md={3} sm={12}>
              <Card
                elevation={0}
                sx={{
                  display: "flex",
                  minHeight: 350,
                  boxShadow:
                    "0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)",
                }}
              >
                <Box
                  display="flex"
                  flexDirection={"column"}
                  justifyContent={"space-between"}
                  my={4}
                  alignItems="center"
                  width={"100%"}
                >
                  <img src={cardItem.img} alt="" height={100} width={100} />
                  <Box>
                    <Typography textTransform={"capitalize"} align="center">
                      {cardItem?.title}
                    </Typography>

                    <Typography
                      variant="body2"
                      align="center"
                      color="#637381"
                      mx={2}
                    >
                      {cardItem?.description}
                    </Typography>
                  </Box>
                  <IconButton onClick={() => navigate(cardItem?.path)}>
                    <East color="primary" />
                  </IconButton>
                </Box>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
