import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import LoginLayout from "../../layouts/login/login_layout";
import { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { useAuth } from "reactfire";
import { useFeedback } from "../../providers/feedback/feeedback";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const auth = useAuth();
  const { showSnackbar } = useFeedback();
  const [userEmail, setUserEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setErrorMsg] = useState({
    email: "",
  });

  async function _forgotPassword() {
    const regEx =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!regEx.test(userEmail)) {
      setErrorMsg({ ...error, email: "Please enter valid email" });
    } else {
      setIsLoading(true);
      await sendPasswordResetEmail(auth, userEmail);
      setIsLoading(false);
      showSnackbar("Reset Email successfully sent. Please check your mail");
    }
  }
  return (
    <LoginLayout>
      <Grid container justifyContent={"center"}>
        <Grid item lg={8} md={8} sm={12} mb={3} width="100%">
          <Box mt={2}>
            <Typography mt={2}>Email address</Typography>
            <TextField
              placeholder="Enter your email address"
              fullWidth
              value={userEmail}
              sx={{ mt: 1 }}
              onChange={(e) => {
                setUserEmail(e.target.value);
                setErrorMsg({ ...error, email: "" });
              }}
              helperText={error.email}
              FormHelperTextProps={{ sx: { color: "red" } }}
            />
          </Box>
          <Box mt={3}>
            {isLoading ? (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent={"center"}
                alignItems="center"
              >
                <CircularProgress />
              </Box>
            ) : (
              <Button
                disabled={userEmail ? false : true}
                variant="contained"
                fullWidth
                size="large"
                onClick={_forgotPassword}
              >
                Send Password Reset Email
              </Button>
            )}
          </Box>
          <Box mt={2}>
            <Button
              variant="text"
              fullWidth
              size="large"
              onClick={() => navigate(-1)}
            >
              Back to login
            </Button>
          </Box>
        </Grid>
      </Grid>
    </LoginLayout>
  );
};

export default ForgotPassword;
